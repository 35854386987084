* {
    box-sizing: border-box;
}

html {
    font-size: 16px;
    line-height: 22px;
    overflow-x: hidden;
    scroll-behavior: smooth;
}

body {
    font-family: 'Inter', sans-serif;
}

h1, h2, h3, h4 {
    margin-top: 0;
    font-family: 'Montserrat', sans-serif;
}

a {
    text-decoration: none;
    color: inherit;
}

p{
    line-height: 26px;
}

h1 {
    font-size: 32px;
    line-height: 40px;
}

h2 {
    margin-bottom: 2rem
}

.button-groups {
    display: flex;
    gap: 1rem;
}

.btn {
    background-color: #3B82F6;
    cursor: pointer;
    border-radius: 4px;
    padding: 1rem 2rem;
    color: white;
    display: inline-flex;
    align-items: center;
}

.btn:hover {
    background-color: #3c77e6;
}

img {
    max-width: 100%;
}

.normal-screenshot {
    border-radius: 5px;
    margin-bottom: 1rem;

    background-color: hsl(217, 91%, 95%);
    padding: 2px;
}

a:hover .normal-screenshot{
    background-color: hsl(217, 91%, 70%);
}

.accordion-line-title {
    display: flex;
    font-weight: 600;
    margin-bottom: 0.5rem;
    align-items: center;
    cursor: pointer;
}

.accordion-line-title div:first-child:hover {
    text-decoration: underline;
}

.accordion-line-title div:first-child {
    flex-grow: 1;
}

.accordion-line-title div:last-child {
    font-size: 20px;
}

.accordion-line {
    border-bottom: 1px solid rgba(0, 0, 0, 5%);
    padding-bottom: 1rem;
    margin-bottom: 1rem;
}

.accordion-description:not(.show){
    display: none;
}

.accordion-line:last-child {
    border-bottom: none;
}

