
.footer {
    display: flex;
    padding: 2rem 0;
}

.footer-logo {
    flex-grow: 1;
    font-weight: 600;
}

.footer-lang-links{
    flex-grow: 1;
    font-weight: 400;
}

.footer-columns{
    display: flex;
    gap: 2rem;
}

.footer-column{
    display: flex;flex-direction: column;
    gap: 1rem;
}

.footer-column a:hover{
    text-decoration: underline;
}
