@import './reset';
@import './basis';

@media(min-width: 800px){

  @import './desktop/layout';
  @import './desktop/footer';
  @import './desktop/hero';
  @import './desktop/pricing';
}

@media(max-width: 800px){

  @import './mobile/style';
}

