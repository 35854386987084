
.pricing-table {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 2rem;
    row-gap: 1rem;
}

.pricing-col{
    overflow: hidden;
    border-radius: 5px;
    display: flex;
    flex-direction: column;

    outline:2px solid hsl(217, 91%, 95%);
}

.pricing-header {
    background-color: #3B82F6;
    color: white;
    height: 200px;
}

.pricing-name {
    text-align: center;
    padding: 1rem 0;
    font-size: 20px;
    line-height: 25px;
}

.pricing-body {
    flex-grow: 1;
}

.pricing-body ul, .pricing-body li {
    list-style: none;
    margin: 0;
    padding: 0;
}

.pricing-body ul {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}


.pricing-amount {
    font-size: 40px;
    font-weight: 700;
    line-height: 50px;
    margin: 2rem 0;
    text-align: center;
}

.pricing-amount .pricing-currency{
    font-size: 20px;
    font-weight: normal;
}

.pricing-amount .pricing-period{
    font-size: 16px;
    font-weight: normal;
}

.pricing-footer {
    text-align: center;
}

.pricing-header, .pricing-body, .pricing-footer {
    padding: 2rem;
}
