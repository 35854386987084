body{
    display: flex;
    flex-direction: column;
    gap: 5rem;
}

.container {
    max-width: 1200px;
    width: 100%;
    padding: 0rem 1rem;
    margin: auto;
    display: flex;
    flex-direction: column;
}

.vertical-spacer {
    display: flex;
    flex-direction: column;
    gap: 3rem;
}

.navbar {
    width: 100%;
    display: flex;
    align-items: center;
    max-width: 1200px;
    margin: auto;
    padding: 1rem 1rem;
}

.navbar-logo {
    display: flex;
    align-items: center;
    font-weight: 600;
    gap: 0.5rem;
}

.navbar-link {
    flex-grow: 1;
    text-align: right
}

.navbar-link a:hover {
    text-decoration: underline;
}

.two-columns {
    display: flex;
    gap: 3rem;
}

.column {
    flex-grow: 1;
    flex-basis: 0
}

.two-columns-skewed {
    display: flex;
    gap: 3rem;
}

.two-columns-skewed .column-left {
    flex-basis: 33%;
}

.two-columns-skewed .column-right {
    flex-basis: 66%;
}

.three-columns {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-row-gap: 4rem;
    grid-column-gap: 3rem;
}
