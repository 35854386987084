body{
    padding: 1rem;
    text-align: center;
    display: flex;
    flex-direction: column;
    gap: 15rem;
}

.navbar {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
}

.hero {
    display: flex;
    flex-direction: column;
}

.hero .container {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
}

.hero .two-columns {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.navbar-logo {
    display: flex;
    align-items: center;
    font-weight: 700;
    flex-grow: 1;
    gap: 0.5rem;
}

.vertical-spacer {
    display: flex;
    flex-direction: column;
    gap: 3rem;
}

ul, li {
    margin: 0;
    padding: 0;
    list-style: none;
}

ul {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.two-columns, .two-columns-skewed {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.three-columns, .two-columns-skewed {
    display: flex;
    flex-direction: column;
    gap: 3rem;
}

.footer, .footer * {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.footer-logo {
    font-weight: 700;
}

.footer-lang-links{
    flex-grow: 1;
    font-weight: 400;
}

.pricing-table {
    text-align: center;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.hero-screenshot {

    background-color: hsl(217, 91%, 95%);
    padding: 2px;
}

.pricing-col {
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    gap: 3rem;
    outline: 2px solid hsl(217, 91%, 95%);
}

.pricing-name {
    font-size: 2rem;
    font-weight: 700;
}

.pricing-header {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    background-color: #3B82F6;color: white;
    padding: 2rem;
}

.pricing-footer{
    padding: 2rem;
}

.pricing-amount {
    font-size: 3rem;
    line-height: 4rem;
    font-weight: 700;
    margin-bottom: 2rem;
}

.pricing-period, .pricing-currency {
    font-size: 1rem;
}

h1, h2, h3, h4 {
    margin-bottom: 1rem;
}

.button-groups {
    justify-content: center;
}
