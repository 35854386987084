@import "minima";

.trail{
  display: flex;
  gap: 1rem;

  ul, li{
    margin: 0; padding: 0; list-style: none;
  }

  li{
    border: 1px solid lightgray;

    width: 2rem; height:2rem;background-color: white; display: flex; align-items: center;justify-content: center;

    a{
      height: 100%;width: 100%;display: inline-flex;align-items: center;justify-content: center;
    }

    &.selected{
      font-weight: bold;
    }
  }
}
