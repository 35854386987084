
.hero {
    min-height: 100vh;
    display: flex;
}


.hero-screenshot {
    max-width: unset;
    position: absolute;
    top: 0;
    left: 0;
    transform: translateY(-50%);
    border-radius: 5px;
    max-height: 80vh;


    background-color: hsl(217, 91%, 95%);
    padding: 2px;
}

.hero .container {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}

.hero .two-columns {
    flex-grow: 1;
    align-items: center;
    display: flex;
}
